<template>
    <div v-if="cargado">
        <div class="form-group">
            <div class="row">
                <div class="col-md-4">
                    <button type="button" @click="nuevanota= !nuevanota" class="btn btn-default">{{
                        $t('general.nuevanota') }}</button>
                </div>
            </div>
            <br>

            <div class="card" v-if="nuevanota==true">
                <div class="card-header bg-gray">
                    <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.nuevanota') }}</h3>
                </div>
                <div class="card-body">
                    <div style="display: grid; grid-template-columns: 5fr 1fr 4fr">
                        <div>
                            <div style="display: grid; grid-template-columns: 9fr 1fr 1fr;">
                                <div class="form-group" style="width: 100%;">
                                    <label for="notaarea">Texto</label>
                                    <textarea placeholder="Texto de la nota" v-model="textonota" rows="3"
                                        class="form-control"></textarea>
                                </div>
                                <div></div>
                                <div
                                    style="display: flex; flex-direction: column; justify-content: end; margin-bottom: 1rem;">
                                    <Button :style="{background:'rgb(34, 139, 57)',border:'1px solid #228b39'}"
                                        @click="crearnota(alarmascreadas)" severity="success">{{ $t('general.anadir')
                                        }}&nbsp;{{ $t('general.nota') }}</Button>
                                </div>
                            </div>
                        </div>
                        <div></div>
                        <!--<div style="display: flex; flex-direction: column; justify-content: space-between; margin-bottom: 1rem; ">
                            <h5>{{ $t('general.programaralarma') }}</h5>
                            <div class="row" style="display: flex; align-items: end; justify-content: space-between; margin: 0;">
                                <div style="display: grid;">
                                    <label>{{ $t('general.usuario') }}: &nbsp;</label>
                                    <select placeholder="Seleccione usuario" class="form-control" v-model="seleccionado">
                                        <option v-for="usuario in usuarios.datos" :value="usuario.idusuario" :key="usuario.idusuario"> {{ usuario.nombre }} </option>
                                    </select>
                                </div>
                                <div style="display: grid;">
                                    <label >{{ $t('general.fecha') }}: &nbsp;</label>
                                    <Calendar style="width: 160px;" id="fnota" v-model="fechanota" :stepMinute="10" showTime hourFormat="24" dateFormat="dd/mm/yy" />
                                </div>
                                <div>
                                    <Button :style="{background:'rgb(34, 139, 57)',border:'1px solid #228b39'}" severity="success" @click="sacarfecha();alarmascreadas.push( {hora:horanuevanota ,fecha:  fechanuevanota ,usuario: seleccionado })"> {{ $t('general.programaralarma') }}</Button>
                                </div>
                            </div>
                            <div v-if="alarmascreadas.length > 0" style="margin-top: 20px;">
                                <div class="row" v-for="alarm in alarmascreadas" :key="alarm">
                                    <div class="col-md-2">
                                    </div>
                                    <div class="col-md-10">
                                        <b> {{ $t('general.alarma') }} {{ $t('general.creada') }} </b> &nbsp;{{ $t('general.usuario') }}: {{alarm.usuario}} &nbsp; {{ $t('general.fecha') }}: {{alarm.fecha}}  {{alarm.hora}} &nbsp;&nbsp;<Button @click="alarmascreadas.splice(alarmascreadas.indexOf(alarm),1)" :style="{height:'1.5rem',width:'1.5rem',background:'red', border: '1px solid red' }" icon="pi pi-times" severity="danger"  rounded />
                                    </div>
                                    <div class="col-md-12">&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header bg-gray">
                        <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.notas') }} {{
                            $t('general.tramitadores') }}</h3>
                    </div>
                    <div class="card-body">
                        <div class="direct-chat-messages" style="height: 550px;">
                            <div class="direct-chat-msg" v-for="nota in notas" :key="nota">
                                <div v-if="nota.tipo_usuario=='tramitador'">
                                    <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-timestamp float-left" style="margin-left: 50px ;">{{
                                            nota.fecha }}</span>
                                    </div>
                                    <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg"
                                        alt="message user image">
                                    <div class="direct-chat-text">
                                        <p @click="nota.anyadiralarma= !nota.anyadiralarma"
                                            style="white-space: preserve-breaks;" v-html="nota.texto"></p>
                                        <button class="btn btn-default" style="font-size: x-small;"
                                            @click="nota.anyadiralarma = !nota.anyadiralarma">Añadir alarma</button>
                                        <p style="font-size:xx-small">&nbsp;</p>

                                        <div v-if="nota.anyadiralarma == true">
                                            <p><b>{{ $t('general.usuario') }}:</b>&nbsp;
                                                <select class="custom-select form-control-sm select2"
                                                    v-model="userseleccionado">
                                                    <option v-for="usuario in usuarios.datos" :value="usuario.idusuario"
                                                        :key="usuario.idusuario"> {{ usuario.nombre }} </option>
                                                </select>
                                                &nbsp;&nbsp;<br>
                                                <b>{{ $t('general.fecha') }}:</b>&nbsp;
                                                <Calendar id="fnota" v-model="fechanota" :stepMinute="10" showTime
                                                    hourFormat="24" dateFormat="dd/mm/yy" />
                                            </p>
                                            <button class="btn btn-default"
                                                @click="sacarfecha();crearalarm(nota.id_nota, fechanuevanota, horanuevanota, userseleccionado);nota.anyadiralarma = false">Nueva
                                                alarma</button>
                                            <hr>
                                        </div>
                                        <div v-if="nota.alarmas">
                                            <h5>{{ $t('general.alarmas') }}</h5>
                                            <hr>
                                            <div v-for="alarma in nota.alarmas" :key="alarma">
                                                <div v-if="alarma.activa_alarma != 'NO'">
                                                    <p><b>{{ $t('general.usuario') }}:</b>&nbsp;{{
                                                        alarma.nombre_usuario_alarma}}&nbsp;&nbsp;<br>
                                                        <b>{{ $t('general.fecha') }}:</b>&nbsp;{{alarma.fecha_alarma
                                                        }}&nbsp;&nbsp;{{alarma.hora_alarma }}
                                                    </p>
                                                    <button v-if="editaralarm != alarma.id_alarma"
                                                        class="btn btn-default" style="font-size: x-small;"
                                                        @click="idseleccionada = alarma.usuario_alarma;editaralarm = alarma.id_alarma">Desactivar
                                                        alarma</button>
                                                    <hr v-if="editaralarm != alarma.id_alarma">
                                                </div>
                                                <div v-if="editaralarm == alarma.id_alarma">
                                                    <!--<label >Motivo</label>-->
                                                    <!--<textarea v-model="motivo" class="form-control"></textarea>-->
                                                    <br>
                                                    <button class="btn btn-default"
                                                        @click="desactivaralarma(nota.id_nota,alarma.id_alarma);editaralarm='0'">Desactivar</button>&nbsp;<button
                                                        class="btn btn-default"
                                                        @click="editaralarm = '0'">Cancelar</button>
                                                    <hr>
                                                </div>
                                                <div v-if="alarma.activa_alarma == 'NO'">
                                                    <p><b>Alarma desactivada</b><br><b>{{ $t('general.usuario')
                                                            }}:</b>&nbsp;{{ alarma.nombre_usuario_alarma
                                                        }}&nbsp;&nbsp;<br>
                                                        <b>{{ $t('general.fecha') }}:</b>&nbsp;{{ alarma.fecha_alarma
                                                        }}&nbsp;&nbsp;{{ alarma.hora_alarma }}
                                                    </p>
                                                    <p style="white-space: preserve-breaks;"
                                                        v-html="alarma.nota_alarma"></p>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header bg-gray">
                        <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.notas') }} {{
                            $t('general.operarios') }}</h3>
                    </div>
                    <div class="card-body">
                        <div class="direct-chat-messages" style="height: 550px;">
                            <div class="direct-chat-msg" v-for="nota in notas" :key="nota.id_nota"
                                @click="console.log(nota)">
                                <div v-if="nota.tipo_usuario == 'operario'">
                                    <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-timestamp float-left" style="margin-left: 50px ;">{{
                                            nota.fecha }}</span>
                                    </div>
                                    <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg"
                                        alt="message user image">
                                    <div class="direct-chat-text" style="display: grid; position: relative;">
                                        <div class="custom-control custom-switch"
                                            style="position: absolute; right: 0; top: 0; margin-right:10px;margin-top:5px;">
                                            <input type="checkbox"
                                                @change="cambiarEstadoNota(nota.id_nota, nota.revisado)"
                                                :id="nota.id_nota" v-model="nota.revisado" class="custom-control-input">
                                            <label class="custom-control-label" :for="nota.id_nota">Revisada</label>
                                        </div>
                                        <p style="white-space: preserve-breaks;"
                                            v-html="nota.tipo_cliente + '<br>' + nota.texto"></p>
                                        <div v-if="nota.archivos.length >0" style="padding: 11px;">
                                            <div class="card card-default">
                                                <div class="card-header ch">
                                                    <h4 class="card-title">{{$t('general.archivos')}}</h4>
                                                    <!--  <Button icon="fas fa-download" style="font-size: 10px;margin-left: 60%;" class="p-button-primary" label="Descargar todo" @click="archivos = nota.archivos; downloadFileWithAnchor()" />  -->
                                                </div>
                                                <div class="card-body" style="padding-bottom: 15px;">
                                                    <div class="row"
                                                        style="justify-content: space-around;row-gap: 10px;">
                                                        <Galleria v-model:activeIndex="nota.activeIndex"
                                                            v-model:visible="nota.displayCustom"
                                                            :responsiveOptions="responsiveOptions"
                                                            :value="nota.archivos" :numVisible="7"
                                                            containerStyle="max-width: 850px" :circular="true"
                                                            :fullScreen="true" :showItemNavigators="true"
                                                            :showThumbnails="false">
                                                            <template #item="slotProps">
                                                                <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr"
                                                                    :src='slotProps.item.url_completa_descarga' preview
                                                                    :alt="slotProps.item.descripcion" />
                                                                <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr"
                                                                    :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id'
                                                                    preview :alt="slotProps.item.descripcion" />
                                                            </template>
                                                            <template #thumbnail="slotProps">
                                                                <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr"
                                                                    :src='slotProps.item.url_completa_descarga' preview
                                                                    :alt="slotProps.item.descripcion" />
                                                                <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr"
                                                                    :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id'
                                                                    preview :alt="slotProps.item.descripcion" />
                                                            </template>
                                                        </Galleria>
                                                        <div v-for="(archivos, index) in nota.archivos" :key="archivos">
                                                            <!-- <a download :id="archivos.id_documento" v-if="urlglobal == 'null.pwgs.app'" :href='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" + id'><i class="fas fa-download"></i></a>-->
                                                            <a download :id="archivos.id_documento"
                                                                :href='archivos.url_completa_descarga'><i
                                                                    class="fas fa-download"></i></a>
                                                            <i title="Mostrar descripción"
                                                                @click="descripcionarchivo = archivos.descripcion; toggle($event)"
                                                                style="margin-left: 20%;font-size: 1.2rem;color: #007bff;cursor: pointer;"
                                                                class="fas fa-info-circle"></i>
                                                            <div style="display: grid" v-if="archivos.image==''">
                                                                <i class="fas fa-file fa-8x"></i>

                                                            </div>
                                                            <div v-else-if="archivos.propiedad_cliente!=0">
                                                                <div class="image-container">
                                                                    <img style="width: 100px; height: 127px;"
                                                                        @click="imageClick(nota, index)"
                                                                        v-if="urlglobal != 'null.pwgs.app'"
                                                                        :src='archivos.url_completa_descarga'
                                                                        width="100" preview
                                                                        :alt="archivos.descripcion" />
                                                                    <img style="width: 100px; height: 127px;"
                                                                        @click="imageClick(nota, index)"
                                                                        v-if="urlglobal == 'null.pwgs.app'"
                                                                        :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + archivos.documento + "&servicio=" +id'
                                                                        width="100" preview
                                                                        :alt="archivos.descripcion" />

                                                                    <!--  <div style="width: 350px;"  class="image-zoom-overlay">
                                                                        <img style="width: 300px; height: 300px;" @click="imageClick(nota, index)" v-if="urlglobal != 'null.pwgs.app'" :src='archivos.url_completa_descarga' :alt="archivos.descripcion" />
                                                                        <img style="width: 300px; height: 300px;" @click="imageClick(nota, index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                                                    </div>-->
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <div class="image-container">
                                                                    <img style="width: 100px; height: 127px;"
                                                                        @click="imageClick(nota, index)"
                                                                        v-if="urlglobal != 'null.pwgs.app'"
                                                                        :src='archivos.url_completa_descarga'
                                                                        width="100" preview
                                                                        :alt="archivos.descripcion" />
                                                                    <img style="width: 100px; height: 127px;"
                                                                        @click="imageClick(nota, index)"
                                                                        v-if="urlglobal == 'null.pwgs.app'"
                                                                        :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id'
                                                                        width="100" preview
                                                                        :alt="archivos.descripcion" />

                                                                    <!--  <div style="width: 350px;" class="image-zoom-overlay">
                                                                        <img style="width: 300px; height: 300px;" @click="imageClick(nota, index)" v-if="urlglobal != 'null.pwgs.app'" :src='archivos.url_completa_descarga' :alt="archivos.descripcion" />
                                                                        <img style="width: 300px; height: 300px;" @click="imageClick(nota, index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id' :alt="archivos.descripcion" />
                                                                    </div>-->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="nota.alarmas">
                                            <div v-for="alarma in nota.alarmas" :key="alarma">
                                                <p>{{ $t('general.alarma') }}:</p>
                                                <p>{{ $t('general.usuario') }}:{{ nombre_usuario_alarma }} {{
                                                    $t('general.remitente2') }}:{{ nombre_remitente_alarma }}</p>
                                                <p>{{ alarma.fecha_alarma }}{{ hora_alarma }}</p>
                                            </div>
                                            <button class="btn btn-default">{{$t('general.editaralarma')}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OverlayPanel ref="opdescripcionnotas">
            <div class="flex flex-column gap-3 w-25rem">
                <b>Descripción del archivo</b>
                <p v-html="descripcionarchivo"></p>
            </div>
        </OverlayPanel>
    </div>
</template>
<script>
import Galleria from 'primevue/galleria';
import { PwgsApi } from '../../../../services/PwgsApi';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';

export default ({
    props: ['id', 'servicio'],
    data() {
        return {
            cargado:false,
            userseleccionado:'',
            notas: [],
            nuevanota: false,
            textonota: '',
            fechanota: new Date(),
            usuarios: '',
            editaralarm: false,
            fechaalarma:'',
            alarmascreadas: [],
            fechanuevanota: '',
            horanuevanota: '',
            urlglobal: "",
            idseleccionada: '',
            motivo: '',
            listaarchivos: [],
            archivos: '',
            descripcionarchivo:'',
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
        }
    },
    setup() {
        return;
    },
    components: {
        Calendar,
        Button, 
        Galleria,
        OverlayPanel,
    },
    methods: {     
        toggle(event) {
            this.$refs.opdescripcionnotas.toggle(event);
        },   
        sacarfecha() { 
            this.fechanuevanota = new Date(this.fechanota).toLocaleDateString("es-ES", { day: 'numeric' }) + "-" + new Date(this.fechanota).toLocaleDateString("es-ES", { month: 'numeric' }) + "-" + new Date(this.fechanota).toLocaleDateString("es-ES", { year: 'numeric' });
            this.horanuevanota = new Date(this.fechanota).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' });
        },
        downloadFileWithAnchor() {
            for (let i = 0; i < this.archivos.length; i++) {
                setTimeout(() => {
                const anchor = document.createElement("a");
                anchor.href = this.archivos[i].url_completa_descarga;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                }, 2500);
            }
        },
     
        async cargarnotas() {
                const api = new PwgsApi();
                // Obtén las notas de la API
                this.notas = await api.get('servicios/' + this.$props.id + '/notas');
                
                // Si notas no es un array, asegúrate de que sea uno
                if (!Array.isArray(this.notas)) {
                    this.notas = [];
                }
                
                // Añadir el campo 'revisado' a cada nota
                if(this.notas.length > 0) {
                    this.notas = this.notas.map(nota => {
                        return this.comprobarRevisado(nota);
                    });
                    for(let nota of this.notas){
                        nota.displayCustom = false;
                        nota.activeIndex = 0;
                    }
                }
                console.log('todasNotas',this.notas);
                this.cargado = true;
        },
        comprobarRevisado(nota) {
            return {
                ...nota,
                revisado: nota.realizado_operario && nota.realizado_operario.toLowerCase() === "si"
            };
        },
        formatearfecha(fecha, hora) {
            this.fechaalarma = fecha + ' ' + hora;
        },
        async listaUsuarios() {
            /* get  modulos/pwgsapi/index.php/usuarios */
            const api = new PwgsApi;
            this.usuarios = await api.get('usuarios/Gestión,Facturación');
        },
        imageClick(nota, index) {
            nota.activeIndex = index;
            nota.displayCustom = true;
        },
        async crearnota() {
            //POST  modulos/pwgsapi/index.php/servicios/:id/notas
            //PUT  modulos/pwgsapi/index.php/servicios/:id/notas/:id_nota
            const api = new PwgsApi;
            const subidadatos = { texto: this.textonota };
            await api.post('servicios/' + this.$props.id + '/notas', subidadatos);
            this.crearalarmas();
            this.cargarnotas();
            this.textonota = "";
        },
        async crearalarmas() {
            const api = new PwgsApi();
            let aux = await api.get('servicios/' + this.$props.id + '/notas');
            let idnota = aux[0].id_nota;
            if (this.alarmascreadas) {
                for (let i = 0; i < this.alarmascreadas.length; i++) {
                    let subidadatosdos = { hora: this.alarmascreadas[i].hora.slice(0, 2), minuto: this.alarmascreadas[i].hora.slice(Math.max(this.alarmascreadas[i].hora.length - 2, 0)), id_usuario: this.alarmascreadas[i].usuario, fecha: this.alarmascreadas[i].fecha.replace(/-/g, "/") }
                    await api.put('servicios/' + this.$props.id + '/notas/' + idnota, subidadatosdos);
                }
            }
        },
        async crearalarm(idnota,fecha,hora,idusuario) {
            const api = new PwgsApi();
            let subidadatosdos = { hora: hora.slice(0, 2), minuto: hora.slice(Math.max(hora.length - 2, 0)), id_usuario: idusuario, fecha: fecha.replace(/-/g, "/") }
            await api.put('servicios/' + this.$props.id + '/notas/' + idnota, subidadatosdos);
            this.cargarnotas();
        },
        async desactivaralarma(idnota, idalarma) {
            const api = new PwgsApi();
            let subidadatos = { id_alarma: idalarma};
            await api.put('servicios/' + this.$props.id + '/notas/' + idnota, subidadatos);
            this.motivo = '';
            this.cargarnotas();
        },
        async cambiarEstadoNota(id_nota, estadoActual) {
            // Aquí puedes manejar la lógica diferente para marcar y desmarcar
            console.log(id_nota, estadoActual);
            if (!estadoActual) {
                this.procesarNotaRevisada(id_nota);
            } else {
                this.procesarNotaNoRevisada(id_nota);
            }
            setTimeout(()=>{
                this.cargarnotas();
            }, 1000);
        },
        async procesarNotaNoRevisada(idnota) {
            const api = new PwgsApi();
            let body = {tipo: "trabajos_no_revisados", ids_avisos: [idnota], estado: 1};
            await api.put('/avisos/revisar-avisos', body);
        },
        async procesarNotaRevisada(idnota) {
            const api = new PwgsApi();
            let body = {tipo: "trabajos_no_revisados", ids_avisos: [idnota], estado: 0};
            await api.put('/avisos/revisar-avisos', body);
        }
 
    },
    mounted() {
        setTimeout(async() => {
            this.urlglobal = localStorage.getItem("direccion");
            this.cargarnotas();
            this.listaUsuarios();                    
        }, 1000);     
       
    },
    watch: {
        id() {
            this.cargarnotas();
        },
    }
})
</script>
<style>
.checknotas {
    width: 12px; /* Ajustar según sea necesario */
    height: 12px; /* Ajustar según sea necesario */
    transform: scale(1.5); /* Escalar el checkbox */
    transform-origin: center; /* Asegurarse de que la escala se aplique desde el centro */
    -webkit-transform: scale(1.5); /* Compatibilidad con navegadores WebKit */
    -ms-transform: scale(1.5); /* Compatibilidad con IE */
    margin: 5px; /* Ajustar el margen si es necesario */
}
.p-galleria-close {

    top: 10%;
    right: 10%;

}
</style>